<template>
    <div class="container">
        <div class="form-header mb-2">
            <label class="label" v-if="name">
                {{name}}
                <a href="" @click.prevent="$emit('cancelled')" class="right ml-20">
                </a>
            </label>
        </div>

        <div class="columns">
            <!-- <div class="column"> -->
            <!-- </div> -->
            <div class="form column is-10 is-offset-1 p-1">
                <ul class="flex-outer is-fluid" >
                    <li v-for="(field, index) in fields" :key="index" v-show="field.type != 'hidden' && ($parent.parameters.form_mode == 'create' && $parent.parameters.current_type.id == 2 && field.name == 'name') == false" class="mb-1" style="flex-direction: column; align-items: normal;">
                        <!-- Form Label  -->
                        <div class="is-full is-flex">
                            <label v-text="field.label + (field.required ? '' : '')"
                                :style="{'color': field.is_invalid ? 'red' : 'white',
                                'text-decoration': field.unique ? 'underline' : 'none'}" class="is-pulled-left" style="width:;"></label>
                        </div>
                        <!-- FORM FIELDS  -->
                        <!-- ############################ -->
                        <!-- Text field -->
                        <div v-if="field.type == 'text' " class="is-flex is-align-items-center" style="justify-content: space-between">
                            <input :disabled="field.disabled || ($parent.parameters.form_mode == 'create' && $parent.parameters.current_type.id == 2 && field.name == 'name') == true" :required="field.required"  
                                class="input mr-2 has-text-light"
                                type="text" v-model="field.value"
                                />        
                                <a @click.prevent="setFieldBoolVal(field, 'print', index)" href="" 
									title="Will be visible in printouts" 
									class="m-1 p-1 button has-background-white" 
									:class="[field.print ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-print mx-2 fa-sm"></i>
                                </a>
                                <a @click.prevent="setFieldBoolVal(field, 'preview', index)"
                                    href="" 
									title="Will be visible in the views" 
									class="m-1 p-1 button has-background-white" 
									:class="[field.preview ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-eye mx-2 fa-sm"></i>
                                </a>
								<a @click.prevent="start_delete_field(field, index)" v-if="!field.value" href="" 
									class="m-1 p-1 button has-background-danger" >
									<i class="fas fa-trash-alt fa-sm mx-2"></i>
								</a>      
                        </div>
                        <!-- Email field -->
                        <div v-if="field.type == 'email'" class="is-flex is-align-items-center">
                            <input :disabled="field.disabled" :required="field.required"  
                                class="input has-text-light"
                                type="email" v-model="field.value"
                                />        
                                <span v-if="field.unique" style="color: darkred">
                                    <i class="fa fa-exclamation-triangle" v-if="field.exists"></i>
                                    {{field.exists ? field.label + ' exists, please set another!' : ''}}
                                </span>
                                <a @click.prevent="setFieldBoolVal(field, 'print', index)" href="" 
									class="m-1 p-1 button" :class="[field.print ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-print mx-2 fa-sm"></i>
                                </a>
                                <a @click.prevent="setFieldBoolVal(field, 'preview', index)" href="" 
									class="m-1 p-1 button" :class="[field.preview ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-eye mx-2 fa-sm"></i>
                                </a>
								<a @click.prevent="start_delete_field(field, index)" v-if="!field.value" href="" 
									class="m-1 p-1 button has-background-danger" >
									<i class="fas fa-trash-alt fa-sm mx-2"></i>
								</a>
                        </div>
                        
                        <!-- Password   -->
                        <input :disabled="field.disabled" v-else-if="field.type == 'password'" type="password" v-model="field.value" />

                        <!-- External supplier  -->
                        <div class="row" v-if="field.type == 'supplier'">
                            <div class="col">
                                <select :disabled="field.disabled" :required="field.required" v-model="field.value" :style="{'color': field.is_invalid ? 'red' : 'black'}" >
                                <option :value="''">--- Zgjidh ---</option>
                                <option
                                    :selected="choice.value == field.selected"
                                    v-for="(choice, idx) in field.choices"
                                    :key="idx"
                                    :value="choice.value"
                                >{{choice.name}}</option>
                                </select>
                            </div>
                        </div>
                        <!-- SELECT 1 --> 
                        <select :disabled="field.disabled" :required="field.required" v-else-if="field.type == 'select'" v-model="field.value" :style="{'color': field.is_invalid ? 'red' : 'black'}" >
                            <option :value="''">--- Select ---</option>
                            <option
                                :selected="choice.value == field.selected"
                                v-for="(choice, idx) in field.choices"
                                :key="idx"
                                :value="choice.value"
                            >{{choice.name}}</option>
                        </select>

                        <!-- Date -->
                        <div v-else-if="field.type == 'date'" class="is-flex is-align-items-center param-date-wrap" >
                            <datepicker 
                                v-model="field.value"
                                format="dd.MM.yyyy"
                                :monday-first='true'
                                :input-class="'input has-text-light'"
                                :wrapper-class="'is-flex-grow-1'"
                                :language="date_lang"
                                :full-month-name="true"
                                :clear-button="true"
                            ></datepicker>
                                
                            <a @click.prevent="setFieldBoolVal(field, 'print', index)" href="" 
                                class="m-1 p-1 button has-background-white" 
                                :class="[field.print ? 'has-background-success' : 'has-background-black']"> 
                                <i class="fas fa-print mx-2 fa-sm"></i>
                            </a>
                            <a @click.prevent="setFieldBoolVal(field, 'preview', index)" href=""
                                class="m-1 p-1 button has-background-white" 
									:class="[field.preview ? 'has-background-success' : 'has-background-black']"> 
                                <i class="fas fa-eye mx-2 fa-sm"></i>
                            </a>
                            <a @click.prevent="start_delete_field(field, index)" v-if="!field.value" href="" 
                                class="m-1 p-1 button has-background-danger" >
                                <i class="fas fa-trash-alt fa-sm mx-2"></i>
                            </a>  
                        </div>

                        <!-- CHECKBOX -->
                        <input v-else-if="field.type == 'checkbox'" type="checkbox"
                            v-model="field.value" true-value="1" false-value="0" style="max-width:20px;">
                        <!-- PRICES -->
                        <div class="prices" v-else-if="field.type == 'prices' ">      
                            <div class="row">
                                <div class="col">
                                    <select class="fluid" v-model="new_price.type">
                                        <option :value="price.type" v-for="(price, index) in field.value.prices_categories" :key="index">
                                        {{price.name}}
                                        </option>
                                    </select>
                                </div>
                                <div class="col"> 
                                    <input type="number" class="ui input fluid" placeholder="Vlera" v-model.number="new_price.value">
                                </div>
                                <div class="col clickable" style="max-width: 40px">
                                    <a href="" class="add-price-btn" @click.prevent="$emit('add-price', new_price); resetNew();">
                                    </a>
                                </div>
                            </div>
                            <div>
                                <div class="row" v-for="(f,ida) in field.value.data" :key="ida">
                                
                                    <div class="col center" style="justify-content: left;border-bottom:1px solid gray;">
                                        <div class="fluid">
                                            <small style="margin-left: 12px;color:gray">KAT: {{f.type}}</small><br>
                                            <b>{{f.name.toUpperCase()}}</b>
                                        </div>
                                    </div>
                                    <div class="col" >
                                        <input type="number" class="input ui fluid" v-model.number="f.value">
                                    </div>
                                    <div class="col" style="max-width: 40px" >
                                        <a style="max-width: 36px" href="" v-if="checkPrice(f)" class="save-price-btn"
                                        @click.prevent="$emit('edit-price', { guid: f.guid, value: f.value}); reset(f);">
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Image input -->
                        <div v-else-if="field.type == 'image'" >
                            <div v-if="!field.value" class="file mb-2">
                                <label class="file-label is-flex">
                                    <input @change="($event) => on_image_change($event, field)" class="file-input" type="file" name="resume">
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">
                                            Choose an image…
                                        </span>
                                    </span>
                                </label>
                            </div>
                            <div v-else class="is-flex">
                                <div class="is-flex is-flex-grow-1">
                                    <span @click.prevent="delete_single_image(field)" class="has-text-danger-dark mr-2 is-clickable">
										<i class="far fa-trash-alt"></i>
									</span>
                                    <span @click.prevent="open_doc_new_tab(field.value)" class="has-background-white has-text-info is-clickable">
                                        <figure class="image">
                                            <img style="max-height:70px; width:auto;" :src="field.value">
                                        </figure>
                                    </span>
                                </div>
                                <a @click.prevent="setFieldBoolVal(field, 'print', index)" href="" 
                                    class="m-1 p-1 button has-background-white" 
                                    :class="[field.print ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-print mx-2 fa-sm"></i>
                                </a>
                                <a @click.prevent="setFieldBoolVal(field, 'preview', index)" href=""
                                    class="m-1 p-1 button has-background-white" 
                                        :class="[field.preview ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-eye mx-2 fa-sm"></i>
                                </a>
                            </div>
                            <!-- <input @change="on_image_change" type="file" id="myfile" name="myfile" multiple> -->
                        </div>

                        <!-- File input -->
                        <div v-else-if="field.type == 'file'" >
                            <div v-if="!field.value" class="file mb-2">
                                <label class="file-label is-flex">
                                    <input @change="($event) => on_file_change($event, field)" multiple
                                        class="file-input" type="file" name="resume" accept=".pdf,.doc">
                                    <span class="file-cta">
                                        <span class="file-icon">
                                            <i class="fas fa-upload"></i>
                                        </span>
                                        <span class="file-label">
                                            Choose a file ...
                                        </span>
                                    </span>
                                </label>
                            </div>
                            <div v-else class="is-flex">
                                <div class="is-flex is-flex-grow-1">
                                    <span @click.prevent="delete_single_doc(field)" class="has-text-danger-dark mr-2 is-clickable">
										<i class="far fa-trash-alt fa-sm"></i>
									</span>
                                    <span @click.prevent="open_doc_new_tab(field.value)" class="has-text-info is-clickable">
                                        {{field.file_name}}
                                    </span>
                                </div>
                                <a @click.prevent="setFieldBoolVal(field, 'print', index)" href="" 
                                    class="m-1 p-1 button has-background-white" 
                                    :class="[field.print ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-print mx-2 fa-sm"></i>
                                </a>
                                <a @click.prevent="setFieldBoolVal(field, 'preview', index)" href=""
                                    class="m-1 p-1 button has-background-white" 
                                        :class="[field.preview ? 'has-background-success' : 'has-background-black']"> 
                                    <i class="fas fa-eye mx-2 fa-sm"></i>
                                </a>
                                <!-- 
								<span @click.prevent="field.value =  ''" 
									class="has-text-danger-dark ml-2 is-clickable">
									<i class="far fa-trash-alt"></i>
								</span> 
								-->
                            </div>

                        </div>
                    </li>
                </ul>
            </div>
        </div>
        
        <div class="columns">
            <div class="column is-12 has-border-top">
                <!-- BUTTONS -->
                    <div style="margin-top:10px;" v-if="fields.length > 0 && $parent.parameters.current_type.id > 2">
                            
						<button type="submit" v-if="!isValid" disabled class="button is-pulled-right"  >
                            <i class="fa fa-exclamation-triangle mr-1"></i> {{saveBtn.name}}
                        </button>
                        <button :disabled="$parent.loading" 
							:class="{'is-loading': $parent.loading }" 
							class="button  is-pulled-right" type="submit" 
							v-else @click.prevent="$emit('submited', fields)">
							{{saveBtn.name}}
						</button>
                        <a href="" @click.prevent="$emit('cancelled')" v-if="cancelBtn.name" 
                            class="button is-pulled-right mr-1">
							{{cancelBtn.name}}
						</a>
                    </div>

            </div>

        </div>
    </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
import {en, de} from 'vuejs-datepicker/dist/locale';
import axios from 'axios'
export default {
    components:{
        Datepicker
    },
    props:[
        'fields',
        'name',
        'saveBtn',
        'cancelBtn',
        'form_width',
        'edit_form'
    ],
    data() {
        return {
            new_price: {},
            date_format: 'DD/MM/YYYY',
            exists: false,
            en: en,
            de: de,
        }
    },
    methods: {
		setFieldBoolVal(field, attribute, index){
			field[attribute] = !field[attribute]
			this.fields[index] = {...field}
			if(this.$parent.parameters.form_mode == 'create'){ 
				this.$parent.parameters.current_type.form_fields[index] = field
				this.$parent.parameters.current_type.form_fields = [...this.$parent.parameters.current_type.form_fields]
				
			}
			else {
				this.$parent.parameters.current_form.form_value[index] = field
				this.$parent.parameters.current_form.form_value = [...this.$parent.parameters.current_form.form_value]
			}

		},
        checkPrice(f){
            return f.value != f.original_value 
        },
        reset(f){
            f.original_value = f.value
        },
        resetNew(){
            this.new_price = {}
        },
        setPrintField(field){
            field = {...field, print: !field.print}
            console.log(field)
        },
        checkIfMustBeUnique(field){
            if(field.unique) {
                console.log('unique', field)
            }
        },
        cancelcallback(){},
        start_delete_field(f, i){
            this.$fire({
                text: 'Are you sure you want to delete this field?',
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                width: 300,
                customClass: {
                    confirmButton: 'button is-success is-small',
                    cancelButton: 'button is-small',
                },
            }).then(response => {
                if(response.value){
                    this.$emit('delete_field',{field: f, index: i})
                }
            })
        },
        on_image_change(e, f){
            var selected_image = e.target.files[0]
            f.img_name = e.target.files[0].name
            this.create_base64_img(selected_image, f)
        },
        create_base64_img(i, f){
            var reader = new FileReader()
            var image = ''
            reader.onload = (e) => {
                image = e.target.result
                f.value = image
            }
            reader.readAsDataURL(i)
        },
        delete_single_image(field){
            this.$fire({
                text: 'Delete this picture?',
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
                width: 300,
                customClass: {
                    confirmButton: 'button is-success is-small',
                    cancelButton: 'button is-small',
                },
            }).then(response => {
                if(response.value){
                    axios.post(this.$tetris_server + '/delete/param/single/image', {
                        field: field,
                        param_guid: this.$parent.parameters.current_form.guid 
                    })
                    .then(() => {
                        // toaster r.data
                        this.$toast('Image deleted.')
                        field.value = null
                    })
                }
            })
        },
        on_file_change(e, f){

            // Base 64 file backup
            var selected_file = e.target.files[0]
            f.file_name = selected_file.name
            if(selected_file){
                let reader = new FileReader();
                reader.onload = e => { 
                    f.value = e.target.result;
                }
                reader.readAsDataURL(selected_file);
            }
        },
        open_doc_new_tab(url){
             window.open(url, '_blank') 
             
        },
        delete_single_doc(field){
            this.$fire({
                text: 'Delete this doc?',
                showCancelButton: true,
                confirmButtonText: this.$t("yes"),
                cancelButtonText: this.$t("no"),
                width: 300,
                customClass: {
                    confirmButton: 'button is-success is-small',
                    cancelButton: 'button is-small',
                },
            }).then(response => {
                if(response.value){
                    axios.post(this.$tetris_server + '/delete/param/single/doc', {
                        field: field,
                        param_guid: this.$parent.parameters.current_form.guid 
                    })
                    .then(() => {
                        this.$toast('PDF deleted.')
                        // toaster r.data
                        field.value = null
                    })
                }
            })
        }
    },
    computed: {
        width(){
            if(this.form_width){
                return this.form_width
            }
            return 'auto'
        },
        isValid(){
            let isformvalid = true
            for(let i=0; i < this.fields.length; i++){
                if(this.fields[i].required == true &&
                    this.fields[i].value &&
                    this.fields[i].value.length == 0){
                    // this.fields[i].is_invalid = true
                    isformvalid = false
                }
            }
            return isformvalid
        },
        curr_lang(){
            return localStorage.lang
        },
        date_lang(){
            var a = this.en
            if(this.$i18n.locale == 'de')
                a = this.de
            return a
        }
    },
    created(){
    }
}
</script>

<style >
    .form{
        font-family: 'Heebo', sans-serif;

    }
    .form .title{
        background: #ebebeb;
        padding: 4px;
        color: black;
        text-transform: uppercase;
        width:100%;
        margin-bottom: 30px;
    }
    /* FLEX FORM */
    .flex-outer,
    .flex-inner {
      list-style-type: none;
      padding: 0;
    }

    .flex-outer {
      
      margin: 0 auto;
    }

    .flex-outer li,
    .flex-inner {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }

    .flex-inner {
      padding: 0 8px;
      justify-content: space-between;  
    }

    .flex-outer > li:not(:last-child) {
      margin-bottom: 4px;
    }

    .flex-outer li label,
    .flex-outer li p {
      padding-top: 8px;
      font-size: 10px;
      letter-spacing: .09em;
      text-transform: uppercase;
      width:100%;
      display: inline-block;
    }

    .flex-outer > li > label,
    .flex-outer li p {
      flex: 1 ;
      max-width: 100%;
    }

    .flex-outer > li > label + *,
    .flex-inner {
      /* flex: 1; */
      width:100%;
    }

    .flex-outer li p {
      margin: 0;
    }
    input[type='number']{
      align-self: flex-end;
    }
    .flex-outer li input:not([type='checkbox']),
    .flex-outer li textarea, select {
      padding: 6px;
      border: 1px solid #bebebe;
      width:100%;
    }

    .flex-outer li button {
      margin-left: auto;
      padding: 8px 16px;
      border: none;
      background: rgb(88, 110, 91);
      color: #f2f2f2;
      text-transform: uppercase;
      letter-spacing: .09em;
      border-radius: 2px;
      cursor: pointer;
    }
    .flex-outer li button:hover, .flex-outer a:hover {
      background: slategray;
    }
  .flex-outer a {
    margin-left: auto;
    padding: 6px 16px;
    border: none;
    background: rgb(156, 150, 131);
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: .09em;
    border-radius: 2px;
    cursor: pointer;
    text-decoration: none;
  }
    .flex-inner li {
      width: 100px;
    }
    .col{
      display: -webkit-box;
      display: -webkit-flex;
      display: -moz-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-align: center;
      -ms-flex-align: center;
      -webkit-align-items: center;
      align-items: center;
    }
    .row:hover{
      background: #f5f5f5;
    }
    .input{
      border: 1px solid tan;
      background-color: transparent;
    }
    .add-price-btn, .save-price-btn{
      background-color: yellowgreen !important;
      padding: 6px 6px !important;
      align-self: end !important;
    }
    .row select {
        width: 100%;
    }
    .time-param-wrap .mx-datepicker {
        width: 100%;
    }
    .param-date-wrap .vdp-datepicker__clear-button{
        position: absolute;
        height: 20px ;
        width: 20px ;
        right: 0px ;
        top: 8px ;
    }
</style>
