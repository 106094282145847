<template>
  <div class="modal" :class="{ 'is-active': this.$parent[show_modal] }">
    <div class="modal-background" @click.self="$emit('close_modal')"></div>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          {{
            parameters.form_mode.toUpperCase() +
            " " +
            parameters.current_type.name.toUpperCase()
          }}
        </p>
        <button
          class="delete"
          aria-label="close"
          @click="$emit('close_modal')"
        ></button>
      </header>
      <section class="modal-card-body">
        <div v-if="parameters.current_type.form_fields">
          <AForm
            :fields="form_fields"
            class="form"
            :saveBtn="{
              name:
                parameters.form_mode.toUpperCase() +
                ' ' +
                parameters.current_type.name.toUpperCase(),
            }"
            :cancelBtn="{ name: '' }"
            :name="parameters.current_type.name"
            @submited="submit_form"
            @delete_field="delete_field"
            @cancelled="parameters.current_type.form_fields = []"
          ></AForm>

          <!-- insert new field to the fieldset -->
          <br />
          <div
            class="ml-6 mr-6"
            v-if="
              parameters.current_type.id == 2 &&
              parameters.form_mode != 'create'
            "
          >
            <div class="is-uppercase is-full is-flex is-justify-content-start">
              <label class="is-pulled-left is-size-7">
                <i class="fa fa-calendar mr-1"></i>
                {{ truck_delivery }}
              </label>
            </div>

            <div class="columns">
              <div class="column is-flex is-align-items-center">
                <input
                  class="input is-small has-text-light"
                  type="date"
                  v-model="truck_arrival_date"
                  v-if="parameters.current_form.status_id < 2"
                />
                <span v-else>{{ truck_arrival_date | customdate }}</span>
              </div>
              <div class="column is-flex is-align-items-center">
                <input
                  class="input is-small has-text-light"
                  type="time"
                  v-model="truck_arrives_at"
                  v-if="parameters.current_form.status_id < 2"
                />
                <span v-else>{{ truck_arrives_at }}</span>
              </div>
            </div>

            <div class="columns">
              <div class="column">
                <div
                  class="is-uppercase is-size-7 is-full is-flex is-justify-content-start"
                >
                  <i class="fa fa-user mr-2"></i>
                  Contact Person
                </div>
                <!-- <pre>   {{JSON.parse(contact_persons[0].form_value)[0].value}} </pre> -->
                <select
                  class="bg-bl has-text-white"
                  v-model="contact_person"
                >
                  <option value="0">[ NO CONTACT PERSON... ]</option>
                  <option
                    :key="contact.guid"
                    v-for="contact in contact_persons"
                    :value="contact.id"
                  >
                    {{
                      contact.form_value[0].value
                        ? contact.form_value[0].value
                        : JSON.parse(contact.form_value)[0].value
                    }}
                  </option>
                </select>
              </div>
            </div>
          </div>
          <br />
          <div class="add-field-wrap p-2" v-if="add_new_field_block">
            <a
              href=""
              @click.prevent="add_new_field_block = false"
              class="is-clear-fix is-pulled-right"
              >x</a
            >
            <label class="label">{{ $t("add_new_field") }}</label>
            <div class="is-flex">
              <div class="select is-fullwidth mr-1 is-small">
                <select
                  v-model="new_field.form_type"
                  class="placeholder-grey"
                  :placeholder="$t('select_type')"
                >
                  <option value="text">{{ $t("text_param_modal") }}</option>
                  <!-- <option value="date">{{ $t("date_field") }}</option>
                  <option value="image">{{ $t("image_field") }}</option> -->
                  <option value="file">{{ $t("pdf_field") }}</option>
                  <!-- <option value="range" selected>Time slot</option> -->
                </select>
              </div>
              <input
                v-model="new_field.name"
                class="input mr-1 is-small has-background-white"
                :placeholder="$t('field_name')"
              />
              <a
                href=""
                @click.prevent="add_new_field"
                class="button mx-1 is-small"
                ><i class="fas fa-plus"></i
              ></a>
            </div>
            <div class="mt-2">
              <label class="checkbox mr-2">
                <input
                  v-model="new_field.print"
                  :value="true"
                  type="checkbox"
                />
                <i class="fas fa-print mx-2 fa-sm"></i>
              </label>
              <label class="checkbox">
                <input
                  :value="true"
                  v-model="new_field.preview"
                  type="checkbox"
                />
                <i class="fas fa-eye mx-2 fa-sm"></i>
              </label>
            </div>
          </div>
          <div v-else class="has-text-right">
            <a href="" @click.prevent="add_new_field_block = true"
              >add more fields</a
            >
          </div>
        </div>
        <hr />

        <div v-if="parameters.current_type.table_name != ''">
          <div
            v-if="!selected_ej_parameter || !selected_ej_parameter.id"
            class=""
          >
            <label class="label"
              >Easyjob {{ parameters.current_type.name }}</label
            >
            <template v-if="parameters.current_type.type == 'contact_person'">
              <input
                v-model="searchQuery"
                class="input has-text-white"
                type="text"
                :placeholder="$t('search_dots')"
                @input="search_external_parameter"
              />
              <table
                v-if="searchQuery && contactResultQuery.length > 0"
                class="table is-hoverable is-fullwidth"
              >
                <thead>
                  <!--<tr>
                                    <td>{{ $t('name_general') }}</td>
								</tr>-->
                </thead>
                <tbody>
                  <tr
                    v-for="(ej_parameter, index) in contactResultQuery"
                    :key="index"
                    @click.prevent="add_external_parameter(ej_parameter)"
                  >
                    <!--<td>
                                        {{ej_parameter.id}}
									</td>-->
                    <td>
                      {{ ej_parameter.name }} |
                      {{ ej_parameter["mobile_phone"] }} |
                      {{ ej_parameter.address }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
            <template v-else>
              <input
                v-model="searchQuery"
                class="input has-text-white"
                type="text"
                :placeholder="$t('search_dots')"
              />
              <table
                v-if="searchQuery && resultQuery.length > 0"
                class="table is-hoverable is-fullwidth"
              >
                <thead></thead>
                <tbody>
                  <tr
                    v-for="(ej_parameter, index) in resultQuery"
                    :key="index"
                    @click.prevent="add_external_parameter(ej_parameter)"
                  >
                    <td>
                      {{ ej_parameter.name }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </div>
          <div v-else class="is-fullwidth">
            <div class="is-cobalt-2 has-text-white p-2">
              <small class="mr-2">EASYJOB Resource</small>|
              {{ parameters.current_type.name }}:
              <!-- <pre>  {{ selected_ej_parameter }}: </pre> -->
              <a
                class="has-text-dark is-pulled-right mr-2 ml-2"
                @click.prevent="selected_ej_parameter = {}"
                href=""
              >
                <i
                  class="fas fa-trash has-text-danger-light ml-2 is-clickable"
                ></i>
              </a>
              <strong class="has-text-dark is-pulled-right">
                {{ selected_ej_parameter.name }}
              </strong>
            </div>
          </div>
          <br />
          <a
            href=""
            @click.prevent="submit_form()"
            :disabled="!selected_ej_parameter.id || loading"
            :class="{ 'is-loading': loading }"
            class="button is-success is-pulled-right"
            style="margin-left: auto"
            >{{ $t("save") }}</a
          >
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AForm from "@/components/a-form/index.vue";
export default {
  props: [
    "show_modal",
    "parameters",
    "direction",
    "current_form",
    "contact_persons",
  ],
  data() {
    return {
      searchQuery: null,
      selected_ej_parameter: {},
      new_field: {
        checkbox: [],
      },
      contactResultQuery: [],
      table_id: 0,
      loading: false,
      add_new_field_block: false,
      truck_arrives_at: "09:00:00",
      truck_delivery: "",
      truck_arrival_date: "1970-01-01",
      contact_person: 0,
    };
  },
  components: {
    AForm,
  },
  created() {
    if (this.parameters.current_form.arrives_at) {
      var sp = this.parameters.current_form.arrives_at.split(" ");

      if (
        this.parameters.current_form.direction == 0 ||
        (this.parameters.current_form.direction == 1 &&
          this.parameters.current_form.transit_related_id !== null)
      ) {
        this.truck_delivery = "Expected arrival onsite";
      } else if (this.parameters.current_form.direction == 1) {
        this.truck_delivery = "Pickup on site";
      }
      // this.parameters.current_form.form_value.map((f) => {
      //   if (f.preview === null || f.preview == undefined ) f.preview = true;
      //   if (f.print === null || f.print == undefined) f.print = true;
      //   return f;
      // });

      this.truck_arrives_at = sp[1];
      this.truck_arrival_date = sp[0];

      this.contact_person = this.current_form.contact_person
        ? this.current_form.contact_person
        : 0;
    }
    if (this.parameters.current_form.table_id > 0) {
      console.log(this.parameters.current_form, "table id");
      this.selected_ej_parameter =
        this.parameters.current_parameters_list.filter(
          (l) => l.id == this.parameters.current_form.table_id
        )[0];
      console.log(this.selected_ej_parameter, "asas");
    } else {
      if (this.parameters.current_type.id == 2) {
        //this.table_id = 6
        this.parameters.current_parameters_list.map((p) => {
          if (p.id == 6) this.selected_ej_parameter = p;
        });
      }
    }
  },
  computed: {
    form_fields() {
      return this.parameters.form_mode == "create"
        ? this.parameters.current_type.form_fields
        : this.parameters.current_form.form_value;
    },
    resultQuery() {
      if (this.searchQuery) {
        return this.parameters.current_parameters_list
          .filter((a) => {
            var not_found = true;
            this.parameters.current_parameters_list.map((e) => {
              if (
                e.table_id == a.id &&
                e.related_id == this.parameters.current_relation
              ) {
                not_found = false;
              }
            });
            return not_found;
          })
          .filter((item) => {
            return this.searchQuery
              .toLowerCase()
              .split(" ")
              .every((v) =>
                JSON.stringify(item).toLowerCase().includes(v.toLowerCase())
              );
          })
          .slice(0, 5);
      } else {
        return this.parameters.current_parameters_list
          .filter((a) => {
            var not_found = true;
            this.parameters.current_parameters_list.map((e) => {
              if (
                e.table_id == a.id &&
                e.related_id == this.parameters.current_relation
              ) {
                not_found = false;
              }
            });
            return not_found;
          })
          .slice(0, 5);
      }
    },
  },
  methods: {
    setField(field, index) {
      if (this.parameters.form_mode == "create") {
        this.parameters.current_type.form_fields[index] = { ...field };
      } else {
        this.parameters.current_form.form_value[index] = { ...field };
      }
    },
    getPreviewFields(fields, prefix) {
      let r = "";
      fields.map((f) => {
        if (f.preview == true)
          r += (f.value ? f.value : this.$t("missing_value_general")) + ", ";
        return f;
      });
      return r == "" ? fields[0].value : prefix + r.substring(0, r.length - 2);
    },
    add_new_field() {
      // If field name is empty
      if (
        this.new_field.name == "" ||
        this.new_field.name == null ||
        !this.new_field.form_type
      ) {
        if (!this.new_field.name)
          this.$toast(this.$t("field_name_is_empty"), "danger");
        if (!this.new_field.form_type)
          this.$toast(this.$t("field_type_is_empty"), "danger");
      } else {
        if (this.parameters.form_mode == "create") {
          this.parameters.current_type.form_fields.push({
            type: this.new_field.form_type,
            name: this.new_field.name,
            label: this.new_field.name,
            value: "",
            required: true,
            print: this.new_field.print ? this.new_field.print : "",
            preview: this.new_field.preview ? this.new_field.preview : "",
          });
        } else {
          this.parameters.current_form.form_value.push({
            type: this.new_field.form_type,
            name: this.new_field.name,
            label: this.new_field.name,
            value: "",
            required: true,
            print: this.new_field.print ? this.new_field.print : "",
            preview: this.new_field.preview ? this.new_field.preview : "",
          });
        }
        this.saveFields();
        this.new_field = {
          checkbox: [],
        };
      }
    },
    saveFields() {
      let parameter_fields =
        this.parameters.form_mode == "create"
          ? this.parameters.current_type.form_fields
          : this.parameters.current_form.form_value;
      if (this.parameters.form_mode == "create")
        axios
          .post(
            this.$tetris_server +
              "/parameter/" +
              this.parameters.current_type.id +
              "/" +
              this.parameters.project,
            { parameter: parameter_fields }
          )
          .then((r) => {
            this.$emit("added param", r.data);
          });
      else {
        axios
          .post(
            this.$tetris_server +
              "/project/parameter/" +
              this.parameters.current_type.id +
              "/" +
              this.parameters.project +
              "/" +
              this.parameters.current_form.guid,
            { parameter: parameter_fields }
          )
          .then((r) => {
            this.$emit("changed param", r.data);
          });
      }
    },
    search_external_parameter() {
      let fields = {
        queryString: this.searchQuery,
        contact: 1,
      };
      if (this.searchQuery != "") {
        axios
          .post(this.$tetris_server + "/get/parameters/contact_person", fields)
          .then((r) => {
            this.contactResultQuery = r.data;
          });
      } else {
        this.contactResultQuery = [];
      }
    },
    delete_field(a) {
      let parameter_fields =
        this.parameters.form_mode == "create"
          ? this.parameters.current_type.form_fields
          : this.parameters.current_form.form_value;
      parameter_fields.splice(a.index, 1);
      this.saveFields();
    },
    add_external_parameter(u) {
      if (this.parameters.form_mode == "create") {
        if (this.parameters.current_type.id == 5) {
          this.parameters.current_type.form_fields.map((fv) => {
            if (fv.name == "name") {
              fv.value = u.name;
            }
            if (fv.name == "mobilenumber") {
              fv.value = u.mobile_phone;
            }
            return fv;
          });
        }
        // console.log(this.parameters.current_type.form_fields, "form_fields");
      } else if (this.parameters.form_mode == "edit") {
        if (this.parameters.current_type.id == 5) {
          this.parameters.current_form.name = u.name;
          this.parameters.current_form.table_id = u.id;
          this.parameters.current_form.data = u;
          this.parameters.current_form.form_value.map((fv) => {
            if (fv.name == "name") {
              fv.value = u.name;
            }
            if (fv.name == "mobilenumber") {
              fv.value = u.mobile_phone;
            }
            return fv;
          });
        }
      }
      this.selected_ej_parameter = u;
    },
    padLeadingZeros(num, size) {
      var s = num + "";
      while (s.length < size) s = "0" + s;
      return s;
    },
    submit_form() {
      // create mode
      if (this.parameters.form_mode == "create") {
        // only for trucks

        if (
          this.parameters.current_type.id == 2 &&
          this.parameters.current_type.form_fields.length > 0
        ) {
          let truck_nr = "0001";
          if (
            this.$parent.trucks_list.filter(
              (a) => a.direction == this.direction
            ).length > 0
          ) {
            let list = [];
            // let returning_list = [];
            let directed_trucks = this.$parent.trucks_list.filter(
              (a) => a.direction == this.direction
            );
            for (var i = 0; i < directed_trucks.length; i++) {
              let arrr = // happens this has already been converted to a json and there are cases not
                typeof directed_trucks[i].form_value == "string"
                  ? JSON.parse(directed_trucks[i].form_value)
                  : directed_trucks[i].form_value;
              // consider only the ones with fields...
              // also just the ones with the current truck prefix...
              // if this was changed should consider the new one

              if (
                arrr.length > 0 &&
                arrr[0].value.includes(this.parameters.truck_prefix)
              )
                list.push(
                  parseInt(
                    arrr[0].value
                      .replace(this.parameters.truck_prefix, "")
                      .substring(1)
                  )
                );
            }
            list = list.sort((a, b) => {
              return a - b;
            });
            truck_nr = this.padLeadingZeros(list[list.length - 1] + 1, 3);
            console.log("before increment", truck_nr, list);
            truck_nr = isNaN(truck_nr)
              ? this.direction + "001"
              : this.direction + parseInt(truck_nr).toString().padStart(3, "0");
          }
          this.parameters.current_type.form_fields[0].value =
            this.parameters.truck_prefix + truck_nr;
        }

        this.loading = true;
        // console.log(this.parameters);
        axios
          .post(
            this.$tetris_server +
              "/" +
              this.parameters.form_mode +
              "/parameter/" +
              this.parameters.current_type.type,
            {
              fields: this.parameters.current_type.form_fields,
              // fields: form_fields,
              relation: this.parameters.current_relation,
              direction: this.direction,
              project: this.parameters.project,
              type_id: this.parameters.current_type.id,
              table_id: this.selected_ej_parameter.id
                ? this.selected_ej_parameter.id
                : 0,
            }
          )
          .then((response) => {
            this.loading = false;
            if (response.status == 200) {
              // upgrade parameter name
              if (this.selected_ej_parameter.id) {
                let a = this.parameters.current_parameters_list.filter(
                  (p) => p.id == this.selected_ej_parameter.id
                );
                let ll = this.getPreviewFields(
                  JSON.parse(response.data.form_value),
                  ""
                );
                response.data.name =
                  (a.length > 0 ? a[0].name : "") +
                  (ll != null ? (ll[0] != "," ? ", " + ll : "") : "");
                response.data.data = a.length > 0 ? a[0].name : "";
              } else {
                if (response.data.form_value)
                  response.data.name = this.getPreviewFields(
                    JSON.parse(response.data.form_value),
                    ""
                  ); //[0].value
                response.data.data = "";
              }
              // add parameter to the list of parameters of this project
              // this.$parent.project_parameters.push(response.data);
              // tell others
              if (this.parameters.current_type.id != 2) {
                this.$parent.project_parameters.push(response.data);
                this.$parent.tell_others({
                  ...response.data,
                  new_data: this.selected_ej_parameter,
                  value: response.data.name.replace(", ", " - "),
                });
              }
              this.$parent[this.show_modal] = false;
              this.$emit("parameter_created", {
                ...response.data,
                new_data: this.selected_ej_parameter,
              });
              this.$toast("Parameter was created successfully", "success");
            } else alert(this.$t("error_comunication"));
          })
          .catch(() => {
            this.loading = false;
          });
      } else {
        axios
          .post(
            this.$tetris_server +
              "/" +
              this.parameters.form_mode +
              "/parameter/" +
              this.parameters.current_type.type,
            {
              fields: this.parameters.current_form.form_value,
              relation: this.parameters.current_relation,
              project: this.parameters.current_form.project_id,
              type_id: this.parameters.current_type.id,
              direction: this.direction,
              truck_time: this.truck_arrives_at,
              truck_date: this.truck_arrival_date,
              job_id: this.parameters.current_form.project_job_id
                ? this.parameters.current_form.project_job_id
                : 0,
              guid: this.parameters.current_form.guid,
              table_id: this.selected_ej_parameter.id
                ? this.selected_ej_parameter.id
                : 0,
              contact_person: this.contact_person,
            }
          )
          .then((r) => {
            if (r.status == 200) {
              // upgrade parameter name
              if (r.data.guid) {
                if (this.selected_ej_parameter.id) {
                  let a = this.parameters.current_parameters_list.filter(
                    (p) => p.id == this.selected_ej_parameter.id
                  );
                  // r.data.name = a.length > 0 ? a[0].name : "(MISSING NAME!)"
                  let dd = this.getPreviewFields(
                    JSON.parse(
                      typeof r.data.form_value == "string"
                        ? r.data.form_value
                        : ""
                    ),
                    ", "
                  );
                  r.data.name =
                    a.length > 0
                      ? a[0].name + (dd != null && dd[0] == "," ? dd : " ")
                      : this.$t("missing_value_general");
                  r.data.data = a.length > 0 ? a[0].name : "";
                } else {
                  if (r.data.form_value)
                    r.data.name = this.getPreviewFields(
                      JSON.parse(r.data.form_value),
                      ""
                    ); //[0].value
                  // JSON.parse(r.data.form_value)[0].value
                  //socket
                  // this.$parent.tell_edits(r.data)
                }

                this.$parent.project_parameters =
                  this.$parent.project_parameters.map((p) => {
                    if (p.guid == r.data.guid) {
                      p = r.data;
                      this.$parent.tell_edits(r.data);
                    }
                    return p;
                  });
              }
              this.$toast("Parameter was edited successfully", "info");
              // add parameter to the list of parameters of this project
              this.$parent[this.show_modal] = false;
            } else alert(this.$t("error_comunication"));
          });
      }
    },
  },
};
</script>

<style>
.bg-transparent {
  background-color: transparent !important;
}

.add-field-wrap {
  border: solid 1px #dedede;
  background: #5e719821;
}
.bg-bl {
  background: #1f2d3b !important;
}
::-webkit-calendar-picker-indicator {
  filter: invert(1);
}
</style>
